<template>
  <div class="singpage-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-51.mp3"),
      },
      timeStampList: [5, 6.9, 9.6, 19, 23.9, 28.5],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Nǐ hǎo! Nǐ hǎo! Nǐmen hǎo!",
            chineseWords: "你好！你好！你们好！",
          },
          {
            id: 2,
            spelling: "Nǐmen xǐhuan chī shénme?",
            chineseWords: "你们喜欢吃什么？",
          },
          {
            id: 3,
            spelling: "Wǒmen xǐhuan chī Běijīng kǎoyā.",
            chineseWords: "我们喜欢吃北京烤鸭。",
          },
          {
            id: 4,
            spelling: "Nǐmen xǐhuan zài nǎr chī Běijīng kǎoyā?",
            chineseWords: "你们喜欢在哪儿吃北京烤鸭？",
          },
          {
            id: 5,
            spelling: "Wǒmen xǐhuan qù Běijīng Fàndiàn chī kǎoyā.",
            chineseWords: "我们喜欢去北京饭店吃烤鸭。",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
